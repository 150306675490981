import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '../../../common/table-redux.tsx';
import { set as setHeaderHeight } from '../../../ops/header/size/action.tsx';
import { getVisibleCoreColumnIds } from '../../../state/consumer/selectors/columns/index.tsx';
import {
	getVisibleAdditionalColumnIds,
	isColumnConfigurationVisible,
} from '../../../state/consumer/selectors/index.tsx';
import { isAutoRowHeight } from '../../../state/consumer/selectors/rows/index.tsx';
import {
	hasVerticallyScrolled,
	getTableWidthWithoutScrollbarOffset,
} from '../../../state/internal/selectors.tsx';

import {
	isHorizontalScrollLeftShadowVisible,
	isHorizontalScrollRightShadowVisible,
} from '../../../state/selectors/index.tsx';

import type { State } from '../../../state/types.tsx';
import AdditionalColumnsContainer from '../common/additional-columns-container/index.tsx';
import ColumnConfiguration from './column-configuration/index.tsx';
import Column from './column/index.tsx';
import ColumnHeaders from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		coreColumnIds: getVisibleCoreColumnIds(state),
		additionalColumnIds: getVisibleAdditionalColumnIds(state),
		isColumnConfigurationVisible: isColumnConfigurationVisible(state),
		hasVerticallyScrolled: hasVerticallyScrolled(state),
		tableHasLeftShadow: isHorizontalScrollLeftShadowVisible(state),
		tableHasRightShadow: isHorizontalScrollRightShadowVisible(state),
		bottomShadowWidth: getTableWidthWithoutScrollbarOffset(state),
		isAutoRowHeight: isAutoRowHeight(state),
		AdditionalColumnsContainer,
		Column,
		ColumnConfiguration,
		...(fg('jsm_a11y_improvements_for_queue_bactch_2')
			? { persistedStoreColumns: state.consumer.persistedStoreColumns }
			: {}),
	}),
	{ setHeaderHeight },
)(ColumnHeaders);
