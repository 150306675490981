import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { ColumnId } from '../../../../../model/columns/index.tsx';
import ColumnWrapper from '../common/column-wrapper/index.tsx';
import type { sortOrder } from '../types.tsx';
import type { SortOrder } from '../../../../../state/consumer/types.tsx';

type Props = {
	id: ColumnId;
	sortOrder?: SortOrder;
};

export default function DynamicColumnHeader(props: Props) {
	const { id, sortOrder } = props;

	let sortOrderLable: sortOrder = 'none';
	if (fg('jsm_a11y_improvements_for_queue_bactch_2')) {
		switch (sortOrder) {
			case 'ASC':
				sortOrderLable = 'ascending';
				break;

			case 'DESC':
				sortOrderLable = 'descending';
				break;

			default:
				sortOrderLable = 'none';
				break;
		}
	}
	return (
		<ColumnWrapper
			id={id}
			{...(fg('jsm_a11y_improvements_for_queue_bactch_2') ? { sortOrder: sortOrderLable } : {})}
		/>
	);
}
