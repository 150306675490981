import React, { type ComponentType } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { ColumnId } from '../../../../model/columns/index.tsx';
import type { SortOrder } from '../../../../state/consumer/types.tsx';

type ComponentProps = {
	id: ColumnId;
	sortOrder?: SortOrder;
};

type Props = {
	id: ColumnId;
	DynamicColumnComponent: ComponentType<ComponentProps>;
	sortOrder?: SortOrder;
};

export default function Column(props: Props) {
	const { id, DynamicColumnComponent, sortOrder } = props;
	return (
		<DynamicColumnComponent
			id={id}
			{...(fg('jsm_a11y_improvements_for_queue_bactch_2') ? { sortOrder } : {})}
		/>
	);
}
